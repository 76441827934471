import React from 'react';
import Cennik from '../components/Cennik/Cennik';
import Layout from '../layout/pl';

const CennikComp = (props) => {
	return (
		<Layout {...props}>
			<Cennik />
		</Layout>
	);
};

export default CennikComp;
