import React from 'react';
import Azzalure from '../../images/AZZALURE.png';
import { FormattedMessage } from 'react-intl';

const CennikMedycynaNew = (props) => {
	return (
		<div>
			<table className="tabelka cennik">
				<tbody>
					<tr>
						<th>
							<FormattedMessage id="pricing.aestheticMedicine" />
						</th>
						<th>
							<FormattedMessage id="pricing.price" />
						</th>
					</tr>
					<tr className="border">
						{/* <th colSpan="2">Cennik zabiegów preparatem <img className="azzalure" src={Azzalure} ale="Azzalure"/></th> */}
						<th colSpan="2">
							<FormattedMessage
								id="pricing.azzalure"
								values={{
									img: (msg) => {
										return <img className="azzalure" src={Azzalure} alt="Azzalure" />;
									}
								}}
							>
								{(...chunks) => <li>{chunks}</li>}
							</FormattedMessage>
						</th>
					</tr>
					<tr className="border">
						{/* <th>Okolica podania</th>
						<th>CENA*</th> */}
						<th>
							<FormattedMessage id="pricing.azzalure.area" />
						</th>
						<th>
							<FormattedMessage id="pricing.price" />
						</th>
					</tr>
					<tr>
						{/* <td>lwia zmarszczka (zmarszczka podłużna czoła)</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td1" />
						</td>

						<td>350 - 500 </td>
					</tr>
					<tr>
						{/* <td>zmarszczki poziome czoła</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td2" />
						</td>
						<td>350 - 500 </td>
					</tr>
					<tr>
						{/* <td>zmarszczki poziome + lwia zmarszczka</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td3" />
						</td>

						<td>600 - 800</td>
					</tr>
					<tr>
						{/* <td>kurze łapki</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td4" />
						</td>

						<td>350 - 500 </td>
					</tr>
					<tr>
						{/* <td>kurze łapki + lwia zmarszczka</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td5" />
						</td>

						<td>600 - 800 </td>
					</tr>
					<tr>
						{/* <td>kurze łapki + zmarszczki poziome + lwia</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td6" />
						</td>

						<td />
					</tr>
					<tr>
						{/* <td>zmarszczka</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td7" />
						</td>

						<td>800 - 1000 </td>
					</tr>
					<tr>
						<td colSpan="2" />
					</tr>
					<tr>
						{/* <td>zmarszczki nosa (zmarszczki królicze)</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td8" />
						</td>

						<td>200 </td>
					</tr>
					<tr>
						{/* <td>uśmiech dziąsłowy</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td9" />
						</td>

						<td>250 </td>
					</tr>
					<tr>
						{/* <td>broda</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td10" />
						</td>

						<td>250 </td>
					</tr>
					<tr>
						<td colSpan="2" />
					</tr>
					<tr>
						{/* <td>leczenie bruksizmu</td> */}
						<td>
							<FormattedMessage id="pricing.azzalure.td11" />
						</td>

						<td>800 - 1000 </td>
					</tr>
				</tbody>
			</table>
			<table className="tabelka cennik">
				<tbody>
					<tr>
						{/* <th>Nadpotliwość pierwotna</th> */}
						<th>
							<FormattedMessage id="pricing.hyperhidrosis" />
						</th>
						<th />
					</tr>
					<tr>
						{/* <td>pachy/stopy/dłonie</td> */}
						<td>
							<FormattedMessage id="pricing.hyperhidrosis.td1" />
						</td>

						<td>od 1 400</td>
					</tr>
				</tbody>
			</table>
			<table className="tabelka cennik">
				<tbody>
					<tr>
						<th colSpan="3" className="">
							<FormattedMessage id="pricing.mesotherapy"/>
						</th>
						{/* <th colSpan="3" className="">
							Mezoterapia igłowa
						</th> */}
					</tr>
					<tr className="border">
						{/* <th>ilość zabiegów</th>
						<th className="nowrap">1 zabieg</th>
						<th className="nowrap">4 zabiegi</th> */}
						<th>
						<FormattedMessage id="pricing.mesotherapy.th1"/>

						</th>
						<th className="nowrap">
							<FormattedMessage id="pricing.mesotherapy.th2"/>
							
						</th>
						<th className="nowrap">
							<FormattedMessage id="pricing.mesotherapy.th3"/>
							
						</th>
					</tr>
					<tr>
						<th className="blue imp" colSpan="3">
							{/* Twarz lub dekolt */}
							<FormattedMessage id="pricing.mesotherapy.th4"/>

						</th>
					</tr>
					<tr>
						{/* <td>Neauvia Hydro Deluxe</td> */}
						<td>
						<FormattedMessage id="pricing.mesotherapy.td1"/>
							
						</td>
						<td>700</td>
						<td>2200</td>
					</tr>
					<tr>
						{/* <td>Filorga NCTF 135HA</td> */}
						<td>
						<FormattedMessage id="pricing.mesotherapy.td2"/>
							
						</td>
						<td>350</td>
						<td>1100</td>
					</tr>
					<tr>
						{/* <td>Linerase (twarz, szyja, dekolt i dłonie jednocześnie)</td> */}
						<td><FormattedMessage id="pricing.mesotherapy.td3"/></td>
						<td>1100</td>
						<td>3500</td>
					</tr>
					<tr>
						{/* <th className="blue imp" colSpan="3">
							Skóra owłosiona głowy
						</th> */}
						<th className="blue imp" colSpan="3"><FormattedMessage id="pricing.mesotherapy.th5"/></th>

					</tr>
					<tr>
						{/* <td>terapia wzmacniająca dr. Cyj</td> */}
						<td><FormattedMessage id="pricing.mesotherapy.td4"/></td>

						<td>800</td>
						<td>2800</td>
					</tr>
					<tr>
						{/* <td>Dermaheal HL</td> */}
						<td><FormattedMessage id="pricing.mesotherapy.td5"/></td>

						<td>300</td>
						<td>1000</td>
					</tr>
				</tbody>
			</table>
			<table className="tabelka cennik">
				<tbody>
					<tr>
						{/* <th colSpan="2">Lipoliza iniekcyjna</th> */}
						<th colSpan="2"><FormattedMessage id="pricing.injection"/></th>
					</tr>
					<tr className="border">
						{/* <th>Lipoliza iniekcyjna</th> */}
						{/* <th>1 ampułka</th> */}
						<th><FormattedMessage id="pricing.injection"/></th>
						<th><FormattedMessage id="pricing.injection.ampule"/></th>

					</tr>
					<tr>
						{/* <td>Aqualyx</td> */}
						<td><FormattedMessage id="pricing.injection.td1"/></td>

						<td>
							300 {' '}
							<span>
								<br />
							</span>{' '}
							{/* Przy 3 lub więcej ampułkach – 250 /amp */}
						<FormattedMessage id="pricing.injection.td1.description"/>
						</td>
					</tr>
					<tr>
						{/* <td>Belkyra - zabieg na podwójny podbródek</td> */}
						<td><FormattedMessage id="pricing.injection.td2"/></td>

						<td>2 000 </td>
					</tr>
					<tr>
						<td colSpan="2" />
					</tr>
					<tr>
						{/* <td>leczenie cellulitu</td> */}
						<td><FormattedMessage id="pricing.injection.td3"/></td>

						<td>
							350
							<span>
								<br />
							</span><FormattedMessage id="pricing.injection.td3.description1"/> – 2100
							<span>
								<br />
							</span><FormattedMessage id="pricing.injection.td3.description2"/> – 3500
						</td>
					</tr>
				</tbody>
			</table>
			<table className="tabelka cennik">
				<tbody>
					<tr>
						{/* <th colSpan="2">Wypełniacze z kwasem hialuronowym NEAUVIA®</th> */}
						<th colSpan="2"><FormattedMessage id="pricing.neauvia"/></th>
					</tr>
					<tr />
					<tr className="border">
						<th><FormattedMessage id="pricing.neauvia.area"/></th>
						<th><FormattedMessage id="pricing.price"/></th>
					</tr>
					<tr>
						{/* <td>Wypełnianie zmarszczek i bruzd</td> */}
						<td><FormattedMessage id="pricing.neauvia.td1"/></td>

						<td>
							1 ml – 1100
							<span>
								<br />
							</span>
							2 ml – 1600
							<span>
								<br />
							</span>
							3 ml – 2200
						</td>
					</tr>
					<tr>
						{/* <td>wolumetria twarzy (modelowanie owalu)</td> */}
						<td><FormattedMessage id="pricing.neauvia.td2"/></td>

						<td>
							1 ml – 1200
							<span>
								<br />
							</span>
							2 ml – 1800
							<span>
								<br />
							</span>
							3 ml – 2400
						</td>
					</tr>
					<tr>
						{/* <td>Modelowanie nosa lub brody</td> */}
						<td><FormattedMessage id="pricing.neauvia.td3"/></td>

						<td>
							0,5 ml – 750
							<span>
								<br />
							</span>
							1 ml – 1200
						</td>
					</tr>
					<tr>
						{/* <td>Wypełnianie doliny łez (Redensity II)</td> */}
						<td><FormattedMessage id="pricing.neauvia.td4"/></td>

						<td>1 ml – 1100 </td>
					</tr>
					<tr>
						{/* <td>Modelowanie i wypełnianie ust</td> */}
						<td><FormattedMessage id="pricing.neauvia.td5"/></td>

						<td>
							0,5 ml – 750
							<span>
								<br />
							</span>
							1 ml – 1200
						</td>
					</tr>
				</tbody>
			</table>
			<table className="tabelka cennik">
				<tbody>
					<tr>
						{/* <th colSpan="3">Laser frakcyjny EMERGE</th> */}
						<th colSpan="3"><FormattedMessage id="pricing.emerge"/></th>
					</tr>
					<tr />
					<tr className="border">
						{/* <th>Okolica podania</th>
						<th>1 zabieg Pakiet</th>
						<th>4 zabiegów + 1 gratis</th> */}
						<th><FormattedMessage id="pricing.emerge.th1"/></th>
						<th><FormattedMessage id="pricing.emerge.th2"/></th>
						<th><FormattedMessage id="pricing.emerge.th3"/></th>

					</tr>
					<tr>
						{/* <td>Twarz</td> */}
						<td><FormattedMessage id="pricing.emerge.td1"/></td>
						<td>800 – 1000 </td>
						<td>3200 - 4000 </td>
					</tr>
					<tr>
						{/* <td>Szyja lub dekolt</td> */}
						<td><FormattedMessage id="pricing.emerge.td2"/></td>

						<td>500 – 600 </td>
						<td>2000 - 2400 </td>
					</tr>
					<tr>
						{/* <td>Twarz + szyja</td> */}
						<td><FormattedMessage id="pricing.emerge.td3"/></td>

						<td>1200 </td>
						<td>4800 </td>
					</tr>
					<tr>
						{/* <td>Twarz + szyja + dekolt</td> */}
						<td><FormattedMessage id="pricing.emerge.td4"/></td>

						<td>1500 </td>
						<td>6000 </td>
					</tr>
					<tr>
						{/* <td>Okolica oczu (kurze łapki)</td> */}
						<td><FormattedMessage id="pricing.emerge.td5"/></td>

						<td>300 </td>
						<td>1200 </td>
					</tr>
					<tr>
						{/* <td>Okolica ust (zmarszczki palacza)</td> */}
						<td><FormattedMessage id="pricing.emerge.td6"/></td>

						<td>300 </td>
						<td>1200 </td>
					</tr>
					<tr>
						{/* <td>Czoło</td> */}
						<td><FormattedMessage id="pricing.emerge.td7"/></td>

						<td>300 – 500 </td>
						<td>1200 - 2000 </td>
					</tr>
					<tr>
						{/* <td>Policzki</td> */}
						<td><FormattedMessage id="pricing.emerge.td8"/></td>

						<td>400 – 500 </td>
						<td>1600 - 2000 </td>
					</tr>
					<tr>
						{/* <td>Dłonie</td> */}
						<td><FormattedMessage id="pricing.emerge.td9"/></td>

						<td>500 – 600 </td>
						<td>2000 - 2400 </td>
					</tr>
					<tr />
				</tbody>
			</table>
			<table className="tabelka cennik">
				<tbody>
					<tr>
						{/* <th colSpan="4">Leczenie blizn lub rozstępów</th> */}
						<th colSpan="4"><FormattedMessage id="pricing.scars"/></th>
					</tr>
					<tr className="border">
						{/* <td>Powierzchnia obszaru poddawanego zabiegowi</td>
						<td>1 zabieg Pakiet</td>
						<td>4 zabiegów + 1 gratis* Pakiet</td>
						<td>8 zabiegów + 2 gratis**</td> */}
						<td><FormattedMessage id="pricing.scars.th1"/></td>
						<td><FormattedMessage id="pricing.scars.th2"/></td>
						<td><FormattedMessage id="pricing.scars.th3"/></td>
						<td><FormattedMessage id="pricing.scars.th4"/></td>

					</tr>
					<tr>
						{/* <td>do 5 cm2</td> */}
						<td><FormattedMessage id="pricing.scars.td1"/></td>

						<td>100 </td>
						<td>400 </td>
						<td>800 </td>
					</tr>
					<tr>
						{/* <td>od 5 do 10 cm2</td> */}
						<td><FormattedMessage id="pricing.scars.td2"/></td>

						<td>150 </td>
						<td>500 </td>
						<td>1200 </td>
					</tr>
					<tr>
						{/* <td>od 10 do 15 cm2</td> */}
						<td><FormattedMessage id="pricing.scars.td3"/></td>

						<td>250 </td>
						<td>1000 </td>
						<td>2000 </td>
					</tr>
					<tr>
						{/* <td>powyżej 15 cm2</td> */}
						{/* <td>cena ustalana indywidualnie</td> */}
						<td><FormattedMessage id="pricing.scars.td4"/></td>
						<td><FormattedMessage id="pricing.scars.td4.description"/></td>

						<td />
						<td />
					</tr>
				</tbody>
			</table>
		</div>
	);
};
export default CennikMedycynaNew;
