import React from 'react';
import CennikStom from './CennikStom';
// import CennikMedycyna from './CennikMedycyna';
import CennikMedycynaNew from './CennikMedycynaNew';
import TitleHeader from '../TitleHeader';
import { FormattedMessage } from 'react-intl';

const Cennik = (props) => {
	return (
		<div className="cennik">
			<TitleHeader title={<FormattedMessage id="menu.pricing" />} />
			
			<div className="content">
				<CennikStom />
				<CennikMedycynaNew />
				{/* <p>* ceny podane w złotych polskich, PLN</p> */}
				<FormattedMessage id="pricing.zl" tagName="p"/>
			</div>
		</div>
	);
};

export default Cennik;
