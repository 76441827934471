import React from "react";
import { FormattedMessage } from 'react-intl';


const CennikStom = props => {
  return (
    <div>
      <table className="tabelka cennik">
        <tbody>
          <tr>
            <th>
              {/* <b>STOMATOLOGIA</b> */}
              <FormattedMessage id="pricing.stomatology" tagName="strong"/>
            </th>
            <th className="center">
              {/* <b>CENA*</b> */}
              <FormattedMessage id="pricing.price" tagName="strong"/>
            </th>
          </tr>
          <tr>
            {/* <td>Przegląd</td>
            <td>50</td> */}
            <td>
              <FormattedMessage id="pricing.stomatology.td1"/>
            </td>
            <td>50</td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id="pricing.stomatology.td2"/>
            </td>
            {/* <td>Konsultacja</td> */}
            <td>100</td>
          </tr>
          <tr>
            {/* <td>Kosztorys leczenia</td> */}
            <td>
              <FormattedMessage id="pricing.stomatology.td3"/>
            </td>
            <td>50</td>
          </tr>
          <tr>
            {/* <td>
              Pakiet diagnostyczny/plan leczenia – 2 wizyty (badanie,
              konsultacja, zdjęcie pantomograficzne, modele diagnostyczne,
              przygotowanie planu leczenia)
            </td> */}
            <td>
              <FormattedMessage id="pricing.stomatology.td4"/>
            </td>
            <td>290</td>
          </tr>
          <tr>
            {/* <td>
              Projektowanie uśmiechu (badanie, konsultacja, wykonanie i analiza
              prototypu „nowego uśmiechu”)
            </td> */}
             <td>
              <FormattedMessage id="pricing.stomatology.td5"/>
            </td>
            <td>450</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            {/* <th colSpan="2">STOMATOLOGIA ZACHOWAWCZA</th> */}
            <th colSpan="2"><FormattedMessage id="pricing.conservativeDentistry"/></th>
          </tr>
          <tr>
            {/* <td>Wypełnienie kompozytowe</td> */}
            <td><FormattedMessage id="pricing.conservativeDentistry.td1"/></td>
            <td>210</td>
          </tr>
          <tr>
            {/* <td>Odbudowa zęba pod pracę protetyczną</td> */}
            <td><FormattedMessage id="pricing.conservativeDentistry.td2"/></td>
            <td>260</td>
          </tr>
          <tr>
            {/* <td>Opatrunek tymczasowy</td> */}
            <td><FormattedMessage id="pricing.conservativeDentistry.td3"/></td>
            <td>110</td>
          </tr>
          <tr>
            {/* <td>Estetyczna korekta kształtu zęba materiałem kompozytowym</td> */}
            <td><FormattedMessage id="pricing.conservativeDentistry.td4"/></td>
            <td>440</td>
          </tr>
          <tr>
            {/* <td>
              Odbudowa zęba wkładem z włókna szklanego – ząb jedno korzeniowy
            </td> */}
            <td><FormattedMessage id="pricing.conservativeDentistry.td5"/></td>

            <td>380</td>
          </tr>
          {/* <tr>
            <td><FormattedMessage id="pricing.conservativeDentistry.td6"/></td>
            <td>390</td>
          </tr> */}
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            {/* <th colSpan="2">LECZENIE ENDODONTYCZNE</th> */}
            <th colSpan="2"><FormattedMessage id="pricing.endodonticTreatment"/></th>
          </tr>
          <tr>
            {/* <td>Interwencja endodontyczna (doraźna pomoc w razie bólu)</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td1"/></td>
            <td>210</td>
          </tr>
          <tr>
            {/* <td>Leczenie endodontyczne – ząb jednokanałowy</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td2"/></td>

            <td>550</td>
          </tr>
          <tr>
            {/* <td>Leczenie endodontyczne – ząb dwukanałowy</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td3"/></td>

            <td>700</td>
          </tr>
          <tr>
            {/* <td>Leczenie endodontyczne – ząb trzykanałowy</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td4"/></td>

            <td>1100</td>
          </tr>
          <tr>
            {/* <td>Leczenie endodontyczne powtórne – ząb jednokanałowy</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td5"/></td>

            <td>700</td>
          </tr>
          <tr>
            {/* <td>Leczenie endodontyczne powtórne – ząb dwukanałowy</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td6"/></td>

            <td>850</td>
          </tr>
          <tr>
            {/* <td>Leczenie endodontyczne powtórne – ząb trzykanałowy</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td7"/></td>

            <td>1300</td>
          </tr>
          <tr>
            {/* <td>Odbudowa zniszczonego zęba do leczenia endodntycznego</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td8"/></td>

            <td>260</td>
          </tr>
          <tr>
            {/* <td>Usunięcie starego wkładu koronowo-korzeniowego</td> */}
            <td><FormattedMessage id="pricing.endodonticTreatment.td9"/></td>

            <td>300</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            {/* <th colSpan="2">ESTETYKA/PROTETYKA</th> */}
            <th colSpan="2"><FormattedMessage id="pricing.aesthetics"/></th>
          </tr>
          <tr>
            {/* <td>Licówka kompozytowa</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td1"/></td>

            <td>780</td>
          </tr>
          <tr>
            {/* <td>Licówka porcelanowa</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td2"/></td>

            <td>1680</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            {/* <td>Korona kompozytowa</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td3"/></td>

            <td>750</td>
          </tr>
          <tr>
            {/* <td>Korona porcelanowa na metalu</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td4"/></td>

            <td>990</td>
          </tr>
          <tr>
            {/* <td>Korona pełnoceramiczna</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td5"/></td>

            <td>1780</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            {/* <td>Inlay / Onlay / Overlay porcelanowy</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td6"/></td>

            <td>1290</td>
          </tr>
          <tr>
            {/* <td>Inlay / Onlay / Overlay kompozytowy</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td7"/></td>

            <td>810</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            {/* <td>Tymczasowa korona / licówka</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td8"/></td>

            <td>150</td>
          </tr>
          <tr>
            {/* <td>Usunięcie starej korony</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td9"/></td>

            <td>120</td>
          </tr>
          <tr>
            {/* <td>Proteza akrylowa częściowa (do 7 zębów)</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td10"/></td>

            <td>950</td>
          </tr>
          <tr>
            {/* <td>Proteza akrylowa częściowa (do 8 zębów)</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td11"/></td>

            <td>1190</td>
          </tr>
          <tr>
            {/* <td>Proteza szkieletowa</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td12"/></td>

            <td>1850</td>
          </tr>
          <tr>
            {/* <td>Prace kombinowane </td> */}
            {/* <td>wycena indywidualna na podstawie planu leczenia</td> */}
            <td><FormattedMessage id="pricing.aesthetics.td13"/></td>
            <td><FormattedMessage id="pricing.aesthetics.td13.description"/></td>

          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2"><FormattedMessage id="pricing.implantology"/></th>
          </tr>
          <tr>            
            {/* <td>Implant</td> */}
            <td><FormattedMessage id="pricing.implantology.td1"/></td>

            <td>3500</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            {/* <td>Podniesienie dna zatoki szczękowej</td> */}
            <td><FormattedMessage id="pricing.implantology.td2"/></td>

            <td>3400</td>
          </tr>
          {/* <tr>
            <td><FormattedMessage id="pricing.implantology.td3"/></td>

            <td>od 1000</td>
          </tr> */}
          <tr>
            {/* <td>Proste usunięcie zęba</td> */}
            <td><FormattedMessage id="pricing.implantology.td4"/></td>

            <td>190</td>
          </tr>
          <tr>
            {/* <td>Chirurgiczne usunięcie zęba</td> */}
            <td><FormattedMessage id="pricing.implantology.td5"/></td>

            <td>490</td>
          </tr>
          <tr>
            {/* <td>Korona na implancie – ceramiczna na łączniku indywidualnym</td> */}
            <td><FormattedMessage id="pricing.implantology.td6"/></td>            
            <td>3300</td>
          </tr>
          <tr>
            {/* <td>Korona na implancie – stalowa na łaczniku standardowym</td> */}
            <td><FormattedMessage id="pricing.implantology.td7"/></td>
            <td>2800</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2"><FormattedMessage id="pricing.orthodonticTreatment"/></th>
          </tr>
          <tr>
            <td><FormattedMessage id="pricing.orthodonticTreatment.td1"/></td>
            <td>200</td>
          </tr>
          <tr>
            <td><FormattedMessage id="pricing.orthodonticTreatment.td2"/></td>
            <td>2600</td>
          </tr>
          <tr>
            <td><FormattedMessage id="pricing.orthodonticTreatment.td3"/></td>
            <td>3800</td>
          </tr>
          <tr>
            <td><FormattedMessage id="pricing.orthodonticTreatment.td4"/></td>
            <td>3300</td>
          </tr>
          <tr>
            <td><FormattedMessage id="pricing.orthodonticTreatment.td5"/></td>
            <td>1600</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2"><FormattedMessage id="pricing.bleaching"/></th>
          </tr>
          <tr>
            {/* <td>Wybielanie domowe – nakładkowe</td> */}
            <td><FormattedMessage id="pricing.bleaching.td1"/></td>

            <td>850</td>
          </tr>
          <tr>
            {/* <td>Wybielanie w gabinecie – systemem Prevdent</td> */}
            <td><FormattedMessage id="pricing.bleaching.td2"/></td>

            <td>1350</td>
          </tr>
          <tr>
            {/* <td>
              Wybielanie przebarwionego zęba po leczeniu kanałowym – pierwsza
              wizyta
            </td> */}
            <td><FormattedMessage id="pricing.bleaching.td3"/></td>

            <td>260</td>
          </tr>
          <tr>
            {/* <td>
              Wybielanie przebarwionego zęba po leczeniu kanałowym – kolejna
              wizyta
            </td> */}
            <td><FormattedMessage id="pricing.bleaching.td4"/></td>

            <td>110</td>
          </tr>
          <tr>
            {/* <td>Fluoryzacja kontaktowa</td> */}
            <td><FormattedMessage id="pricing.bleaching.td5"/></td>
            <td>120</td>
          </tr>
          {/* <tr>
            <td><FormattedMessage id="pricing.bleaching.td6"/></td>
            <td>500</td>
          </tr> */}
          <tr>
            {/* <td>
              Higienizacja jamy ustnej (usunięcie kamienia, piaskowanie,
              fluoryzacja)
            </td> */}
            <td><FormattedMessage id="pricing.bleaching.td7"/></td>

            <td>250</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2"><FormattedMessage id="pricing.xRay"/></th>
          </tr>
          <tr>
            {/* <td>rtg punktowe</td> */}
            <td><FormattedMessage id="pricing.xRay.td1"/></td>

            <td>35</td>
          </tr>
          <tr>
            {/* <td>rtg pantomograficzne</td> */}
            <td><FormattedMessage id="pricing.xRay.td2"/></td>
            
            <td>80</td>
          </tr>
          <tr>
            {/* <td>rtg cefalometryczne</td> */}
            <td><FormattedMessage id="pricing.xRay.td3"/></td>

            <td>100</td>
          </tr>
          <tr>
            {/* <td>cbct – endo ct</td> */}
            <td><FormattedMessage id="pricing.xRay.td4"/></td>

            <td>180</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2"><FormattedMessage id="pricing.record"/></th>
          </tr>
          <tr>
            {/* <td>Odpis za jedną stronę</td> */}
            <td><FormattedMessage id="pricing.record.td1"/></td>

            <td>9,03</td>
          </tr>
          <tr>
            {/* <td>Kopia/Wydruk za jedną stronę</td> */}
            <td><FormattedMessage id="pricing.record.td2"/></td>

            <td>0,32</td>
          </tr>
          <tr>
            {/* <td>
              Dokumentacja na informatycznym nośniku danych(całość dokumentacji)
            </td> */}
            <td><FormattedMessage id="pricing.record.td3"/></td>

            <td>1,81</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CennikStom;
